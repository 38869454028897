import { render } from "./AddPhotos.vue?vue&type=template&id=79bcf95c"
import script from "./AddPhotos.ts?vue&type=script&lang=ts"
export * from "./AddPhotos.ts?vue&type=script&lang=ts"

import "./addPhotos.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "79bcf95c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('79bcf95c', script)) {
    api.reload('79bcf95c', script)
  }
  
  module.hot.accept("./AddPhotos.vue?vue&type=template&id=79bcf95c", () => {
    api.rerender('79bcf95c', render)
  })

}

script.__file = "src/views/Profile/components/AddPhotos/AddPhotos.vue"

export default script